import React from "react";

class Contactos extends React.Component {
  render() {
    return (
      <div className="p-1 mx-auto">
        <h5 className="text-center mb-3">
          Podés contactarte con nosotros por los siguientes medios:
        </h5>

        <div className="bg-dark text-light rounded text-center p-2 mb-4">
          <span
            className="iconify mr-2 mb-1"
            data-icon="logos:whatsapp"
            data-inline="false"
            data-width="20px"
          ></span>
          <span className="mr-2">
            <b>Whatsapp:</b>
          </span>
          <span>299 592-9406 / 9420</span>
        </div>

        <div className="bg-dark text-light rounded text-center p-2 mb-4">
          <span
            className="iconify mr-2 mb-1"
            data-icon="ant-design:mail-outlined"
            data-inline="false"
          ></span>
          <span>contacto@laneuquinaonline.com.ar</span>
        </div>

        <p className="text-small">Lunes a Sábados: 08:00hs a 22:00hs.</p>
      </div>
    );
  }
}

export default Contactos;
