import React from "react";

class TerminosCondiciones extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="p-1 mx-auto">
        <h4 className="text-center mb-3">TÉRMINOS Y CONDICIONES</h4>

        <div className="text-small text-justify">
          La Neuquina Online es una plataforma online o sitio web de juegos de
          azar debidamente autorizado y aprobado por el Instituto Provincial de
          Juegos de Azar, autoridad de Aplicación de Juegos de Azar de la
          Provincia de Neuquén
          <br></br>
          Al utilizar la plataforma de juego o sitio WEB, usted reconoce y
          acepta que:
          <br></br>
          <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
            <li>
              Ha leído, comprendido y aceptado estos Términos y Condiciones. Que
              estos Términos y Condiciones constituyen un acuerdo legalmente
              vinculante entre usted y La Neuquina Online sobre el uso de la
              plataforma de juego o sitio web.
            </li>
            <li>
              Que todas las apuestas serán aceptadas únicamente conforme a estos
              términos y condiciones, y serán la confirmación de que el
              participante en las apuestas conoce estas reglas y está totalmente
              de acuerdo con las mismas.
            </li>
            <li>
              Las previsiones que conforman este reglamento o sus anexos se
              aplicaran por igual a todas las apuestas realizadas por internet,
              cualquiera sea el medio y/o dispositivo utilizado, incluidas
              aplicaciones descargables para dispositivos móviles y sin
              distinción del juego a que tales apuestas refieran, entendiendo
              que las referencias al uso de la página web lo son también al uso
              de las apuestas por internet y/o por medio de aplicaciones para
              dispositivos móviles.
            </li>
            <li>
              La utilización de la plataforma por parte del usuario, implica la
              aceptación de toda la normativa aplicable a la actividad -vigente
              o futura- ya sea esta emitida por el IJAN, por otro ente oficial
              de juego de la República Argentina, por la UIF, por la AFIP o por
              cualquier otro organismo dentro de la competencia que le
              corresponda.
            </li>
          </ul>
          El usuario asume voluntariamente los riesgos propios de la
          participación en apuestas a juegos de azar y de la utilización de la
          plataforma de juego o sitio web, conociendo que se hallan a su
          disposición mesa de ayuda y sistema de prevención del juego
          compulsivo.
        </div>

        <h5 className="text-center mb-3 mt-3">CONDICIONES DE USO</h5>
        <div className="text-small text-justify">
          Como condición del uso de lo plataforma de juego o sitio web, usted
          garantiza y asume la responsabilidad de que no utilizará ni accederá a
          la misma, a sus Servicios, a el Software y/o a la Información para un
          propósito que sea ilícito bajo cualquier legislación que sea aplicable
          o que esté prohibido y/o incumpla estos Términos y condiciones.
          <br></br>
          En particular usted garantiza, comprometiéndose a ello como condición
          necesaria y excluyente para el uso de la plataforma de juego o sitio
          web:
          <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
            <li>Que actúa en representación propia.</li>
            <li>Que sus capacidades legales no están limitadas.</li>
            <li>
              Que no se le ha diagnosticado ni ha sido clasificado como
              ludópata.
            </li>
            <li>Que tiene más de 18 años.</li>
            <li>
              Que es plenamente consciente del riesgo de pérdida de dinero en el
              transcurso del uso de los Servicios.
            </li>
            <li>
              Que no está depositando dinero procedente de actividades
              delictivas u otras actividades ilegales o no autorizadas.
            </li>
            <li>
              Que no está realizando actividades delictivas, ilícitas ni no
              autorizadas ni planea utilizar su cuenta abierta con nosotros en
              conexión con dicho tipo de actividades y que no utilizará ni
              permitirá que otras personas utilicen la plataforma de juego, ni
              su cuenta de apuesta para actividades delictivas o ilícitas entre
              las que se incluyen, pero no se limitan a, blanqueo de dinero,
              bajo cualquier ley aplicable a usted o a nosotros.
            </li>
            <li>
              Que mantendrá su nombre de usuario, su número de cuenta y su
              contraseña en confidencialidad y protegidos contra acceso o uso no
              autorizados y para ello deberá cambiar su contraseña
              inmediatamente o avisarnos inmediatamente en caso de violación de
              la confidencialidad de su nombre de usuario, contraseña, La
              Neuquina Online no se responsabiliza si terceros acceden a su
              cuenta.{" "}
            </li>
            <li>
              Que Usted será el único responsable de todas las actividades que
              tengan lugar durante el acceso y el uso de la plataforma de juego
              y/o los Dispositivos bajo su nombre de usuario, su número de
              cuenta, con independencia de si dicho acceso y/o uso ha sido
              autorizado por usted o con su consentimiento.
            </li>
            <li>
              Qué no utilizará la plataforma de juego, los Sitios Web, los
              Dispositivos, el Software o la Información de cualquier modo que
              interfiera o pueda interferir con la disponibilidad de la
              plataforma de juego Servicios y los Sitios Web para otros usuarios
              y no hacer nada que dificulte o pueda dificultar el funcionamiento
              operativo de los Servicios y los Sitios Web; no solicitar ni
              intentar de ningún modo obtener información relativa a otros
              usuarios; que su acceso a la plataforma de juego y la Información
              en y a través de los Sitios Web y/o los Dispositivos no es ilegal
              ni está prohibido por leyes aplicables a usted ni obligaciones
              contractuales aplicables a usted personalmente.
            </li>
          </ul>
          La Neuquina Online no será ni se hará responsable de ninguna falla o
          problema que surja debido al equipo informático de un cliente, su
          conexión a internet o por la provisión del servicio de
          telecomunicaciones, incluido a los que impidan al cliente realizar
          apuestas, o ver o recibir cierta información en relación a eventos en
          particular.
        </div>

        <h5 className="text-center mb-3 mt-3">
          REALIZACIÓN DE APUESTAS Y PROCEDIMIENTO DE ACEPTACIÓN DE APUESTAS
        </h5>
        <h6 className="text-center mb-3 mt-3">Registro de usuario</h6>
        <div className="text-small text-justify">
          Para realizar apuestas por dinero y participar en las ofertas de juego
          que ofrece La Neuquina Online, es indispensable completar el proceso
          de Registración como usuario de la plataforma o sitio web.
          <br></br>
          Al registrarse se abrirá automáticamente a nombre del usuario una{" "}
          <b>cuenta gratuita y sin costo alguno</b>. En dicha cuenta se
          reflejarán todos los movimientos monetarios que realiza el usuario en
          la plataforma.
          <br></br>
          Para registrarse, el cliente deberá complementar correctamente bajo fe
          de juramento y en su totalidad el formulario de inscripción
          preestablecido.
          <br></br>
          Es responsabilidad del usuario brindar datos veraces, reales y
          correctos. La Neuquina Online no se responsabiliza en caso de que el
          usuario brinde información errónea. Como medida de prevención, la
          plataforma La Neuquina Online puede solicitar al usuario
          identificación personal (documento de identidad, pasaporte), a fin de
          verificar que la información que el usuario presentó en el momento de
          la suscripción es veraz. El no cumplimiento de esta cláusula podrá
          implicar la, denegación de su inscripción, congelación de su cuenta
          y/o baja del servicio.
          <br></br>
          El usuario se compromete a presentar, toda la documentación e
          información necesaria para que La Neuquina Online y el sistema La
          Neuquina Online confirme la veracidad de los datos por usted
          registrados, en caso de que La Neuquina Online lo solicite. Si la
          información personal brindada por el usuario no es correcta la
          plataforma La Neuquina Online bloqueará la cuenta y retendrá el
          balance de la misma (sin derecho a indemnización alguna o intereses
          para el usuario) hasta que el usuario presente la documentación o
          información solicitada.
          <br></br>
          El usuario es responsable de mantener su información personal (e-mail,
          dirección, número telefónico, etc.) actualizado y cualquier cambio en
          la información deberá ser de forma inmediata.
          <br></br>
          Toda la información proporcionada por usted se tratará de forma segura
          y en estricta conformidad con las leyes pertinentes de protección de
          datos. Usted puede actualizar su información personal en cualquier
          momento en la sección Accesos /Datos Personales del sitio Web o bien
          llamando por teléfono al equipo de atención al cliente{" "}
          <b>299 592-9406 / 9420</b> quienes lo asesorarán al respecto.
          <br></br>
          Los siguientes sujetos no podrán ser usuarios:
          <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
            <li>Personas Jurídicas.</li>
            <li>
              Aquellas personas participantes de los eventos y/o sorteos en los
              cuales están colocadas las apuestas (personal de la Lotería o de
              otras organizaciones de juegos de azar, atletas, entrenadores,
              jueces y otros).
            </li>
            <li>
              Personas bajo efectos del alcohol o drogas; así como también
              personas incompetentes.
            </li>
            <li>Menores de 18 años.</li>
            <li>
              Alcanzados por exclusión judicial y/o{" "}
              {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                ? "autolimitación"
                : "autoexclusión"}{" "}
              y/o auto restricción, con relación a las apuestas a juegos de azar
              hasta su rehabilitación judicial, informe profesional de
              rehabilitación o cumplimiento del plazo de restricción.
            </li>
            <li>
              Quienes están comprendido en alguna cláusula prohibitiva del
              reglamento y/o normas que la complementen.
            </li>
          </ul>
          A los usuarios que ya se encuentren registrados les estará prohibido
          inscribirse como nuevos clientes / usuarios indicando otro nombre u
          otra dirección de e-mail. Si La Neuquina Online detecta alguna
          conexión entre cuentas de usuario, asumirá la existencia de registros
          múltiples y, por consiguiente, lo considerará como una infracción de
          los presentes Términos y Condiciones. En tales casos, La Neuquina
          Online está autorizado a anular cualquier premio o ganancia
          transferida a una cuenta de usuario, así como a invalidar cualquier
          premio obtenido. La sospecha de posesión de varias cuentas sólo se
          puede contrarrestar si el usuario es capaz de demostrar sin dudas que
          los titulares de las cuentas son personas diferentes y que ha cumplido
          con todas las condiciones expuestas en estos Términos y Condiciones.
          <br></br>
          Las apuestas serán realizadas de manera válida si su nombre de usuario
          y contraseña han sido introducidos correctamente, sujetas siempre a
          que haya fondos disponibles suficientes en su cuenta.
          <br></br>
          Es exclusiva responsabilidad suya realizar correctamente las apuestas,
          una vez que sus apuestas hayan sido realizadas y se hayan confirmado,
          no pueden ser canceladas, ni ser modificadas y se considerarán pruebas
          concluyentes de las apuestas que usted ha realizado.
          <br></br>
          Tenga en cuenta que todas las apuestas son registradas en la base de
          datos del registro de transacción. Los registros de transacción son
          pruebas concluyentes de todas las transacciones y de la hora a la que
          se realizaron dichas transacciones.
          <br></br>
          La Neuquina Online no se responsabiliza por el mal funcionamiento de
          los dispositivos informáticos o de comunicación utilizados para
          acceder a la plataforma.
          <br></br>
          Las apuestas estarán todas sujetas a las correspondientes normas de
          apuesta aplicables a cada juego y a estos Términos y condiciones. En
          caso de mal funcionamiento del sistema de la plataforma de juego de La
          Neuquina Online, este se reserva el derecho a invalidar cualquiera o
          todas las apuestas realizadas.
          <br></br>
          Los usuarios serán responsables de verificar que sus apuestas en
          Internet sean válidas en su lugar de residencia.
          <br></br>
          Los usuarios solo podrán realizar apuestas por internet mediante los
          dispositivos permitidos y a los juegos autorizados, debiendo utilizar
          solo la plataforma o sitio web La Neuquina Online.
          <br></br>
          Todas las apuestas son realizadas a entera discreción y riesgo del
          usuario. Solo serán aceptadas aquellas apuestas realizadas a través de
          internet.
          <br></br>
          Es responsabilidad del usuario comprobar que los datos de sus apuestas
          sean los correctos, ya que una vez realizadas no podrán ser canceladas
          ni modificadas.
          <br></br>
          Cada apuesta válida recibirá un código único de transacción y La
          Neuquina Online no se hace responsable de la determinación de
          cualquier apuesta que no se realice con un código único de
          transacción.
          <br></br>
          En caso de duda acerca de la validez de una apuesta, se recomienda a
          los usuarios verificar el historial de apuestas, o bien ponerse en
          contacto con la mesa de ayuda.
          <br></br>
          Si existieren desacuerdos, tanto del usuario como de parte de La
          Neuquina Online, convienen en aceptar como definitivo el registro de
          transacciones que surja de la base de datos del sistema.
          <br></br>
          Se anularán todas las apuestas si el resultado parcial o total ya se
          hubiera determinado antes de realizar la apuesta.
        </div>

        <h6 className="text-center mb-3 mt-3">
          Créditos: su carga y utilización
        </h6>

        <p className="text-small text-justify">
          El usuario, una vez registrado en cualquier momento podrá acceder a la
          carga de créditos, apostar y acreditar sus ganancias, excepto en los
          casos que por cumplimientos legales o situaciones previstas en los
          Términos y Condiciones así se lo impidan.
        </p>

        <h6 className="text-center mb-3 mt-3">
          Acceso al Historial del Cliente
        </h6>

        <p className="text-small text-justify">
          Los clientes pueden acceder fácilmente a un historial de las últimas
          transacciones, apuestas, retiros o depósitos que se han realizado en
          la plataforma. El saldo de un cliente estará siempre disponible en la
          sección correspondiente y puede verse una vez que ha ingresado a su
          cuenta.
        </p>

        <h6 className="text-center mb-3 mt-3">Cierre de Cuentas</h6>

        <p className="text-small text-justify">
          La Neuquina Online se reserva el derecho de admisión de cualquier
          solicitud de registro, de cualquier persona sin importar si este
          formulario alguna vez fue aceptado, sin más aviso que la comunicación
          por medio de correo electrónico consignado por el cliente en el
          formulario de registro y/o por la mera denegación del acceso a la
          plataforma o sitio web.
          <br></br>
          Ante cualquier conducta indebida, como ser: insultos, amenazas,
          difamación en medios de comunicación, redes sociales, y/o cualquier
          medio que provoque un daño en la imagen de La Neuquina Online por
          parte de los usuarios, provocará el cierre de la cuenta La Neuquina
          Online y se reserva el derecho a defender legalmente sus intereses.
          <br></br>
          Es requisito indispensable, que los clientes mantengan el buen trato y
          decoro para con el sitio, y personal disponible en la atención al
          cliente. Caso contrario, el área gerencial determinara el cierre
          definitivo de la cuenta.
          <br></br>
          Así mismo, es obligación de La Neuquina Online mantener el mismo trato
          para con los clientes, esforzándose en evacuar cualquier tipo de
          reclamo y/o sugerencias sin distinción alguna, apuntando a la
          celeridad y buen servicio.
          <br></br>
          La Neuquina Online, se reserva el derecho de admisión de fondos si sus
          fuentes son de orígenes dudosos o injustificables, por lo que
          ocasionara que el equipo de auditoría realice el control pertinente
          sobre la cuenta del usuario, con el seguimiento constante de la
          conducta del usuario. El usuario acepta y permite los controles de La
          Neuquina Online para la prevención de lavado de activos y/o
          actividades ilícitas y/o contrarias a los términos y condiciones de la
          plataforma de juego.
        </p>

        <h6 className="text-center mb-3 mt-3">Alerta de Usuarios</h6>

        <div className="text-small text-justify">
          La Neuquina Online cuenta con un equipo de trabajo que se encuentran
          plenamente capacitado para detectar y actuar en forma inmediata ante
          la detección de potenciales agentes considerados de riesgos para
          nuestra empresa, tales como:
          <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
            <li>Menores de Edad</li>
            <li>Ludópatas</li>
            <li>Transacciones Fraudulentas</li>
            <li>Lavado de Activos.</li>
          </ul>
          La Neuquina Online, se reserva el derecho de admisión de fondos si sus
          fuentes son de orígenes dudosos o injustificables, por lo que
          ocasionara que el equipo de auditoría realice el control pertinente
          sobre la cuenta del usuario, con el seguimiento constante de la
          conducta del usuario. El usuario acepta y permite los controles de La
          Neuquina Online para la prevención de lavado de activos y/o
          actividades ilícitas y/o contrarias a los términos y condiciones de la
          plataforma de juego.
        </div>

        <h6 className="text-center mb-3 mt-3">
          El Juego y los menores de edad
        </h6>

        <p className="text-small text-justify">
          La ley prohíbe a los menores de dieciocho (18) años jugar por dinero y
          La Neuquina Online considera muy seriamente esta responsabilidad. En
          el supuesto de lograr identificar un menor de edad registrado,
          apostando en nuestro sitio online, inmediatamente procederemos a
          comunicarnos con el menor, solicitando mayor información sobre sus
          Tutores a los efectos de comunicar esta situación, bloquearemos su
          cuenta, y el mismo perderá todo derecho sobre las transacciones
          realizadas. Además, se informará inmediatamente a la autoridad
          competente.
        </p>

        <h6 className="text-center mb-3 mt-3">
          Unidad monetaria – Limitaciones financieras
        </h6>

        <p className="text-small text-justify">
          La plataforma acepta como unidad monetaria al Peso ($AR) y se reserva
          el derecho a suprimir la realización de apuestas en cualquier otra
          moneda.
          <br></br>
          La Neuquina Online se reserva el derecho de limitar la apuesta máxima,
          como así también cambiar los límites de apuestas para clientes en
          particular sin previo aviso, encontrándonos plenamente facultados para
          decidir el curso de los límites. Las limitaciones o exclusiones a
          clientes de la plataforma, no dará derecho a reclamo alguno por parte
          del usuario, habiendo éste reconocido que la plataforma hace uso del
          derecho de admisión y permanencia.
        </p>

        <h6 className="text-center mb-3 mt-3">Fondos</h6>

        <p className="text-small text-justify">
          Los fondos ingresados por los usuarios en sus cuentas se encuentran
          protegidos. Contamos con cuentas exclusivas para los apostadores, no
          pudiendo La Neuquina Online ni El Instituto de Juegos de Azar de
          Neuquén disponer de dichos fondos. De esta forma se otorga
          confiabilidad en las transacciones.
        </p>

        <h6 className="text-center mb-3 mt-3">Juego responsable</h6>

        <div className="text-small text-justify">
          Si el juego empieza a ser un problema, pedí ayuda llamando al 299 403
          8900 interno 205 o comunicándote al correo electrónico
          juego.responsable@ijan-neuquen.com.
          <br></br>
          La Reservación de Derechos, Terminación, Quejas, Cierre de Cuenta o
          Suspensión del Servicio
          <br></br>
          La Neuquina Online se reserva el derecho, a su exclusivo criterio, a
          declarar nulas las ganancias y a decomisar el saldo de su cuenta de
          apuesta, y suspender la prestación de los Servicios/desactivar su
          cuenta si La Neuquina Online dispone de indicios suficientes para
          creer o comprueba lo siguiente:
          <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
            <li>Usted tiene más de una cuenta activa con La Neuquina Online</li>
            <li>Proporciona información de registro incorrecta o engañosa;</li>
            <li>
              No ha suministrado o se ha negado a suministrar la información de
              identificación solicitada;
            </li>
            <li>No ha cumplido la Mayoría de edad;</li>
            <li>
              Ha sido descubierto estafando o tratando de estafar o se ha
              descubierto que ha estafado a alguien;
            </li>
            <li>
              Si La Neuquina Online. ha determinado que usted ha empleado o
              hecho uso de un sistema de inteligencia artificial o de otra
              naturaleza (incluidas máquinas, ordenadores, software u otros
              sistemas automatizados) específicamente diseñados para anular el
              sistema para defraudar a La Neuquina Online.
            </li>
            <li>
              Ha permitido (intencional o in intencionadamente) que otra persona
              usara su cuenta;
            </li>
            <li>Incumple alguno de estos Términos y condiciones;</li>
            <li>
              Usted ha reconocido que está, o nosotros creemos razonablemente
              que esté sufriendo de un problema relacionado con la ludopatía;
            </li>
            <li>
              Autoridad judicial, policial u cualquier otra autoridad o entidad
              pública o privada lo requiera.
            </li>
          </ul>
          Reservamos el derecho de retener el pago o cantidad ganadora, bloquear
          depósitos o transacciones de apuestas hasta que la identidad del
          ganador estuviese verificada a nuestra satisfacción para asegurar que
          el pago de la cantidad ganadora se hiciera a la persona correcta y
          para cumplir con los requisitos de la Unidad de Información Financiera
          al verificar la identidad del usuario.
        </div>

        <h6 className="text-center mb-3 mt-3">Suspensión de juegos</h6>

        <p className="text-small text-justify">
          Nos reservamos el derecho, sin responsabilidad con ninguna parte, a
          nuestro exclusivo criterio y sin el deber de notificárselo, a añadir
          nuevos juegos o funciones a la plataforma o sitio web, a comenzar,
          cesar, suspender, restringir el acceso o modificar cualquier juego o
          función en cualquier momento.
        </p>

        <h6 className="text-center mb-3 mt-3">Puntos de venta</h6>

        <p className="text-small text-justify">
          Solamente las agencias oficiales podrán ser autorizadas a intervenir
          en la venta de créditos y pago de premios al usuario que se relacione
          al sistema de apuestas por internet implementado por el presente
          reglamento.
          <br></br>
          Los puntos de venta serán fehacientemente informados vía circular de
          toda modificación que afecte el presente reglamento o la modalidad de
          las apuestas por internet.
        </p>

        <h6 className="text-center mb-3 mt-3">Licencia de software</h6>

        <p className="text-small text-justify">
          Por el presente, reconoce y acepta que el servicio que se pone a su
          disposición para la utilización de la plataforma de juego es propiedad
          de La Neuquina Online. y/o su licenciante, y que usted no obtiene
          ningún derecho sobre dicho servicio.
        </p>

        <h6 className="text-center mb-3 mt-3">Dispositivos Permitidos</h6>

        <p className="text-small text-justify">
          Quedan permitidos para la formalización de apuestas por internet en la
          plataforma o sitio web, las computadoras y/o ordenadores personales
          y/o teléfonos celulares inteligentes y/u otro dispositivo o medio de
          comunicación a distancia que pueda conectarse a internet por medio de
          un browser compatible con el sistema.
          <br></br>
          La tecnología de tales dispositivos deberá permitir, además de la
          realización de apuestas y seguimiento del juego, el registro e
          identificación del usuario y el correspondiente control de
          estadísticas, cuentas, movimientos, operaciones y/o transacciones de
          quien los utilice. La Neuquina Online no se hace responsable por las
          limitaciones, errores o fallas de los dispositivos elegidos por el
          usuario.
        </p>

        <h6 className="text-center mb-3 mt-3">
          Premios Mayores – Procedimiento según Normativas Unidad de Información
          Financiera
        </h6>

        <p className="text-small text-justify">
          En el caso en que un apostador sea beneficiario de premios mayores,
          que superen los Pesos Ciento Cuarenta Mil ($140.000) fijado por la
          Unidad de Información Financiera (UIF), en la Resolución 199/11,
          Resolución 70/2011 y sus modificatorias, los fondos serán
          inmovilizados hasta tanto el usuario cumpla con lo establecido por
          dicho Organismo, en el marco de las medidas de prevención de lavados
          de activos y financiación de terrorismo, exigidas a los sujetos
          obligados de la industria del juego de azar
          <br></br>
          Esta situación generará una notificación al cliente, informando los
          pasos que debe seguir para cumplir con el procedimiento, el que
          consistirá en completar y firmar de puño y letra el formulario de
          “Declaración Jurada de Datos Personales” y el formulario “Declaración
          Jurada Sobre la Condición de Persona Expuesta Políticamente (PEP)”.
          <br></br>
          Ambos formularios podrá firmarlos en su agencia amiga o en
          instalaciones del Instituto Provincial de Juegos de Azar.
          <br></br>
          Los fondos serán liberados una vez que el operador verifique la
          recepción de ambos formularios completos y debidamente firmados.
        </p>
        <div className="text-small text-justify">
          Normativa citada:
          <br></br>
          <b>Resolución 199/2011:</b>
          <br></br>
          <b>Artículo 14º .- </b>Datos a requerir a Personas Físicas. En el caso
          que el cliente sea una persona física, los Sujetos Obligados deberán
          recabar la información establecida por el artículo 21 bis de la Ley N°
          25.246 y modificatorias consistente en:
          <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
            <li>Nombre y apellido completos;</li>
            <li>Fecha y lugar de nacimiento;</li>
            <li>Nacionalidad;</li>
            <li>Sexo;</li>
            <li>Estado civil;</li>
            <li>
              Número y tipo de documento de identidad que deberá exhibir en
              original y al que deberá extraérsele una copia. Se aceptarán como
              documentos válidos para acreditar la identidad: Documento Nacional
              de Identidad, Libreta Cívica, Libreta de Enrolamiento, Pasaporte,
              Cédula de Identidad del MERCOSUR, y documentos de identidad de
              países limítrofes autorizados para la entrada al país por la
              Dirección Nacional de Migraciones, todos vigentes al momento de
              celebrar la operación;
            </li>
            <li>
              C.U.I.L. (clave única de identificación laboral), C.U.I.T. (clave
              única de identificación tributaria) o C.D.I. (clave de
              identificación). Este requisito será exigible a extranjeros en
              caso de corresponder.
            </li>
            <li>
              Domicilio real (calle, número, localidad, provincia) y código
              postal;
            </li>
            <li>Número de teléfono;</li>
            <li>
              Declaración Jurada de profesión, oficio, industria o actividad
              principal que realice.
            </li>
          </ul>
        </div>
        <div className="text-small text-justify">
          <b>Artículo 16º .- </b>Los Sujetos Obligados deberán:
          <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
            <li>
              En todos los casos adoptar medidas adicionales razonables, a fin
              de identificar al beneficiario final y verificar su identidad.
              Asimismo, se deberá verificar que los clientes no se encuentren
              incluidos en los listados de terroristas y/u organizaciones
              terroristas de conformidad con lo prescripto en la Resolución UIF
              vigente en la materia.
            </li>
            <li>
              Cumplimentar la Resolución UIF N° 11/2011, requiriendo de los
              clientes la correspondiente información.
            </li>
          </ul>
        </div>

        <p className="text-small text-justify">
          <b>Resolución 70/2011</b>
          <br></br>
          <b>Artículo 7º .- </b>A partir del 1º de agosto de 2011 las personas
          físicas o jurídicas que como actividad habitual exploten Juegos de
          Azar definidas como Sujetos Obligados en la Resolución UIF Nº 18/2011
          (B.O. 20/01/2011), deberán informar hasta el día QUINCE (15) de cada
          mes las operaciones que realicen los apostadores que efectúen
          cobranzas de premios o cambios de valores o cambio de fichas o
          equivalente por montos superiores a PESOS CINCUENTA ($50.000),
          realizadas en el mes calendario inmediato anterior.
        </p>

        <p className="text-small text-justify">
          <b>Modificado por: RESOLUCIÓN U.I.F. 117/19 </b>
          <br></br>
          Ciudad de Buenos Aires, 13/11/2019
          <br></br>
          Fecha de publicación 19/11/2019
          <br></br>
          <b>Artículo 18º .- </b>Sustituir el texto del artículo 7° de la
          Resolución UIF N° 70/2011 por el siguiente: “Las personas físicas o
          jurídicas que como actividad habitual exploten Juegos de Azar
          definidas como Sujetos Obligados en la Resolución UIF Nº 18/2011,
          deberán informar hasta el día QUINCE (15) de cada mes las operaciones
          que realicen los apostadores que efectúen cobranzas de premios o
          cambios de valores o cambio de fichas o equivalente por montos
          superiores a PESOS CIENTO CUARENTA MIL ($ 140.000), realizadas en el
          mes calendario inmediato anterior.”
        </p>

        <h6 className="text-center mb-3 mt-3">
          Violaciones de estos Términos y condiciones
        </h6>

        <p className="text-small text-justify">
          La Neuquina Online se reserva el derecho a buscar todas las soluciones
          disponibles por ley o equidad en relación con cualquier violación de
          estos Términos y condiciones, incluido el derecho a denegar o
          restringir el acceso a los Servicios, los Sitios Web, la plataforma de
          juego y la Información a una persona determinada, o a bloquear el
          acceso de una dirección de Internet específica o a través de otros
          Dispositivos a los Servicios, los Sitios Web, plataforma de juego y la
          Información, en cualquier momento y a su exclusivo criterio, sin tener
          que proporcionar motivos cualesquiera.
        </p>

        <h6 className="text-center mb-3 mt-3">
          Derecho y jurisdicción aplicables
        </h6>

        <p className="text-small text-justify">
          La modalidad o sistema de apuestas objeto del presente reglamento,
          solo podrá comercializarse en el territorio de la provincia de Neuquén
          y/o en aquellas Jurisdicciones territoriales que mediante convenio
          suscripto pudieran adherir a la comercialización de la presente.
          <br></br>
          Usted acepta que su acceso a los Servicios, a los Sitios Web, a la
          plataforma de juego al Software y a la Información, la utilización de
          los mismos y la interpretación de estos Términos y condiciones se
          rijan e interpreten con arreglo a las leyes de la República Argentina
          sometiéndose a la competencia de los tribunales ordinarios de la
          Ciudad de Neuquén, República Argentina, renunciando expresamente al
          fuero federal y/o cualquier otro fuero que pudiera corresponder.
        </p>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default TerminosCondiciones;
